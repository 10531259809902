import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import "./solid-hero.sass"
import Img from "gatsby-image"

const SolidHero = ({ title, subtitle, primary, imageFluid, imageAlt }) => {

  return <div
    className={classNames("_8j", { "_8n": primary }, { "_8p": imageFluid && imageAlt })}>
    <div className={"_8q"}>
      <div className="_15">


      <h1 className={"_8s"}>{title}</h1>
      <h2 className={"_4j _4j _8r"}>{subtitle()}</h2>
      </div>
    </div>
      {imageFluid && imageAlt &&
      <div className={'_8t'}>

        <div className="_15">

        <Img imgStyle={{ "objectFit": "contain" }} className={"_8u"}
             fluid={imageFluid} alt={imageAlt}></Img>
        </div>
        </div>
      }
  </div>
}

SolidHero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.func.isRequired,
  imageFluid: PropTypes.any,
  imageAlt: PropTypes.string,
  primary: PropTypes.bool,
}

export default SolidHero
